import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { FormsModule } from "@angular/forms"

import { NotificacionesModule } from "@puntaje/puntaje/new-modules/notificaciones"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"

import { routing } from "./alumnos_notificaciones.routing"
import { PuntajeNotificacionesModule } from "@puntaje/puntaje/core"

@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        NotificacionesModule, 
        AlumnosLayoutsModule, 
        routing,
        PuntajeNotificacionesModule
    ],
    exports: [],
    declarations: [],
    providers: []
})
export class AlumnosNotificacionesModule {}
