import { Routes, RouterModule } from "@angular/router"
import { TokenGuard } from "@puntaje/puntaje/services"
import { AuthGuard } from "@puntaje/shared/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    DiagnosticoComponent,
    PlanesPersonalesComponent,
    PlanPersonalSesionComponent,
} from "@puntaje/puntaje/core"

export const routes: Routes = [
    {
        path: "planes_personales",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanesPersonalesComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "diagnostico",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: DiagnosticoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "plan_personal/:id/ciclo/:ciclo/sesiones/:sesion_id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanPersonalSesionComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const routing = RouterModule.forChild(routes)
