import { NgModule } from "@angular/core"
import { CommonModule } from "@angular/common"
import { AlumnosPlanClasesRouting } from "./alumnos-plan-clases-routing"
import { AlumnosLayoutsModule } from "../layouts/alumnos_layouts.module"
import { PuntajePlanClasesModule } from "@puntaje/puntaje/core"


@NgModule({
    declarations: [],
    imports: [
        CommonModule, 
        AlumnosPlanClasesRouting, 
        AlumnosLayoutsModule,
        PuntajePlanClasesModule
    ]
})
export class AlumnosPlanClasesModule {}
