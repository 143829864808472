import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { ClasePlanClaseComponent, PlanClaseAlumnoComponent, PlanClasesAlumnosComponent } from "@puntaje/puntaje/core"

const routes: Routes = [
    {
        path: "plan_clases",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClasesAlumnosComponent,
                canActivate: [AuthGuard],
                data: { titulo: "" }
            }
        ]
    },
    {
        path: "plan_clases/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlanClaseAlumnoComponent,
                canActivate: [AuthGuard],
                data: { titulo: "" }
            }
        ]
    },
    {
        path: "plan_clases/:id/sesiones/:sesionId",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ClasePlanClaseComponent,
                canActivate: [AuthGuard],
                data: { enableReporte: false, showVerLibro: false, isLibroFrom: "alumno", planUrl: "/plan_clases" }
            }
        ]
    },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AlumnosPlanClasesRouting {}
