import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"
import { LandingComponent } from "./landing.component"
import { HomeComponent } from "./home.component"

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { SharedLayoutComponent } from "@puntaje/platforms/zero"
import { PlaceholderPageComponent, TermsAndConditionsComponent } from "@puntaje/puntaje/core"

export const LandingRoutes: Routes = [
    { path: "landing", component: LandingComponent },
    { path: "landing/home", redirectTo: "home", pathMatch: "full" },
    {
        path: "home",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: HomeComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "condicionesUso",
        component: SharedLayoutComponent,
        children: [
            {
                path: "",
                component: TermsAndConditionsComponent
            }
        ]
    },
    {
        path: "pronto",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: PlaceholderPageComponent
            }
        ]
    }
]

export const LandingRouting = RouterModule.forChild(LandingRoutes)
