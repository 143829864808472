import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { TokenGuard } from "@puntaje/puntaje/services"

import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import {
    BibliotecaComponent,
    MaterialAppsComponent,
    MaterialComponent,
    MaterialUDDComponent,
    RecursosInteractivosComponent,
    MaterialesComponent
} from "@puntaje/puntaje/core"

export const materialesRoutes: Routes = [
    {
        path: "materiales",
        component: MaterialesComponent,
        canActivate: [TokenGuard, AuthGuard]
    },
    {
        path: "materiales/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RecursosInteractivosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "blibliotecas/recursos_interactivos",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: RecursosInteractivosComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "material_udd",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialUDDComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "material_app",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: MaterialAppsComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "biblioteca",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: BibliotecaComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
    // { path: "material_app", component: MaterialAppsComponent, canActivate: [TokenGuard, AuthGuard] },
    // { path: "biblioteca", component: BibliotecaComponent, canActivate: [TokenGuard, AuthGuard] }
    //{ path: "bibliotecas/biblioteca", component: BibliotecaComponent, canActivate: [TokenGuard, AuthGuard] },
    //{ path: "bibliotecas", component: HomeBibliotecaComponent, canActivate: [TokenGuard, AuthGuard] }
]

export const materialesRouting = RouterModule.forChild(materialesRoutes)
