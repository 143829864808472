import { Routes, RouterModule } from "@angular/router"
import { AuthGuard } from "@puntaje/shared/core"
import { LoadAsignaturaGuard, TokenGuard } from "@puntaje/puntaje/services"
import { LoggedLayoutComponent } from "../layouts/logged_layout/logged_layout.component"
import { RestrictedLayoutComponent } from "../layouts/restricted_layout/restricted_layout.component"
import {
    EstadisticaEvaluacionComponent,
    EstadisticasAlumnoComponent,
    EvaluacionInstanciaEstadisticasComponent,
    ProgresoAlumnoComponent,
    TutorEstadisticasComponent
} from "@puntaje/puntaje/core"

export const estadisticasRoutes: Routes = [
    {
        path: "estadisticas",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticasAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "tutor/estadisticas",
        component: RestrictedLayoutComponent,
        children: [
            {
                path: "",
                component: TutorEstadisticasComponent,
                canActivate: [TokenGuard, AuthGuard, LoadAsignaturaGuard]
            }
        ]
    },
    {
        path: "mi_progreso",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: ProgresoAlumnoComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "ensayo_instancias/estadisticas/:id",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EvaluacionInstanciaEstadisticasComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    },
    {
        path: "evaluacion/:id/estadistica",
        component: LoggedLayoutComponent,
        children: [
            {
                path: "",
                component: EstadisticaEvaluacionComponent,
                canActivate: [TokenGuard, AuthGuard]
            }
        ]
    }
]

export const estadisticasRouting = RouterModule.forChild(estadisticasRoutes)
